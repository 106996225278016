import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Example from "../components/SalePanel";
import MobileHomePage from "../pages/MobileHomePage";
import MobileMarketplace from "../pages/MobileMarketplace";
import Login from "../pages/Login";
import MobileMyList from "../pages/MobileMyList";
import EthicsPage from "../pages/EthicsPage";
import TermsPage from "../pages/TermsPage";
import PressPage from "../pages/PressPage";
import PrivacyPage from "../pages/PrivacyPage";
import { Wallet } from "../pages/wallets";
import { getCategories, getProductNames } from "../Redux/options/slice";
import { getProductsList } from "../Redux/products/slice";
import { ProtectedRoute } from "./ProtectedRoute";
import MobileTransactionExplorer from "../components/MobileTransactionExplorer";
import MyGPT from "../pages/VertoGPT";
import CertificatePage from '../components/CertificatePage';

const MainContent = () => {
  return (
    <div className="d-flex flex-column sticky-footer" style={{ overflow: "hidden" }}>
      <Routes>
        <Route
          index
          element={
            // <ProtectedRoute>
            <MobileHomePage />
            // </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          // path="/home/:level"
          path="/marketplace"
          element={
            // <ProtectedRoute>
            <MobileMarketplace />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/my-list"
          element={
            <ProtectedRoute>
              <MobileMyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet/:userId/:walletId"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transaction-explorer"
          element={<MobileTransactionExplorer />}
        />
        <Route
          // path="/home/:level"
          path="/gpt"
          element={
            <MyGPT />
          }
        />
        <Route
          path="/ethics"
          element={<EthicsPage />}
        />
        <Route
          path="/terms"
          element={<TermsPage />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPage />}
        />
        <Route
          path="/press"
          element={<PressPage />}
        />
        <Route path="/certificate/:id" element={<CertificatePage/>} />
      </Routes>
    </div>
  );
};

const MobileWebRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getProductsList());
    // dispatch(getProductNames())
  }, []);

  return (
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
  );
};

export default MobileWebRouter;
